import React, { useState } from 'react'
import CloseLink from '../CloseLink'

const videos = [
  {
    id: 6,
    name: 'That Song',
    youtube: 'ikfT7hzACDE'
  },
  {
    id: 5,
    name: 'Friends',
    youtube: 'WSsAsYJtCho'
  },
  {
    id: 1,
    name: 'Welcome Back',
    youtube: 'VaM6VGdJZYM'
  },
  {
    id: 2,
    name: 'Love Games',
    youtube: 'QZLzDMfj9-0'
  },
  {
    id: 3,
    name: 'Trigger',
    youtube: 'YayIifmAWvw'
  },
  {
    id: 4,
    name: 'All In',
    youtube: 'VUJEyBpkYbY'
  }
]

const Watch = () => {
  const [activeVideoIdx, setActiveVideo] = useState(0)
  return (
    <div className='z-40 absolute top-20 md:top-0 right-0 bottom-0 glass w-full md:w-3/5'>
      <CloseLink />
      <div className='h-full w-full overflow-y-auto hide-scrollbar'>
        <div className='p-4 md:p-16'>
          <div className='pt-4 px-4 pb-4 bg-white'>
            <div className='flex justify-center mb-4'>
              {videos.map((video, idx) =>
                <button 
                  onClick={() => setActiveVideo(idx)}
                  key={video.id} 
                  className={`relative inline-block py-2 px-4 text-purple uppercase text-sm lg:text-lg tracking-wider font-bold focus:outline-none ${activeVideoIdx === idx ? 'opacity-100 show-active-butterfly' : 'opacity-50'}`}>
                    {video.name}
                    {/* <div className='active-butterfly-small'>
                      <Butterfly />
                    </div> */}
                </button>
              )}
            </div>
            <div className='relative pt-16:9 w-full'>
              <iframe 
                src={`https://www.youtube.com/embed/${videos[activeVideoIdx].youtube}`}
                className="absolute inset-0 w-full h-full" 
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                title="Video"
                allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Watch