import React from 'react'
import Butterfly from '../Butterfly'

const Follow = () => {
  return (
    <div className='z-40 absolute top-0 right-0 bottom-0 bg-black w-full md:w-3/5'>
      <div className='flex h-full w-full justify-center items-center'>
        <div style={{ width: '200px' }}>
          <Butterfly />
        </div>
      </div>
    </div>
  )
}

export default Follow