import React from 'react'
import CloseLink from '../CloseLink'
import Artwork from '../../media/that_song/artwork.jpg'

const links = [
  {
    id: 1,
    label: 'Apple Music',
    icon: 'fab fa-apple',
    link: 'https://music.apple.com/us/album/niiva/1533289688'
  },
  {
    id: 2,
    label: 'Spotify',
    icon: 'fab fa-spotify',
    link: 'https://open.spotify.com/album/4DoVWd6Ak9O9Qo5dUs8KEx?si=ebywrWmEQCSIMTU6hJSeCw'
  },
  {
    id: 3,
    label: 'Deezer',
    icon: 'fab fa-deezer',
    link: 'https://www.deezer.com/us/album/175305102'
  },
  {
    id: 4,
    label: 'Amazon Music',
    icon:'fab fa-amazon',
    link: 'https://music.amazon.com/albums/B08JWKXR63?tag=ffm01f-20'
  },
  {
    id: 5,
    label: 'Youtube',
    icon: 'fab fa-youtube',
    link: 'https://www.youtube.com/playlist?list=OLAK5uy_nfn7pEVe3P2zG8QXHcio4uQp91fGNXzfw'
  },
  {
    id: 6,
    label: 'Tidal',
    icon: 'fas fa-music',
    link: 'https://tidal.com/browse/album/156289918'
  }
]

const Listen = () => {
  return (
    <div className='z-40 absolute top-20 md:top-0 right-0 bottom-0 glass w-full md:w-3/5'>
      <CloseLink />
      <div className='h-full w-full overflow-y-auto hide-scrollbar'>
        <div className='p-4 md:p-16'>
        <div className='p-4 md:p-8 bg-white mb-4'>

          <div className='flex'>
            <div className='w-1/5'>
              <img src={Artwork}  className='block w-full' alt="The Song Single Artwork"/>
            </div>
            <div className='w-4/5'>
              <div className='pl-8'>
                <h2 className='text-xl md:text-4xl uppercase tracking-wider pb-4 font-bold'>"That Song" Single</h2>
                <div className='block md:flex -mx-4'>
                  <div className='w-full'>
                    <a
                      href="https://orcd.co/thatsong"
                      className="flex items-center justify-center border border-niiva-purple border-opacity-50 rounded-full py-2 px-4 mb-2 transition hover:border-opacity-100" 
                      target="_BLANK" rel="noopener noreferrer">
                      <i className={`mr-2`}></i> Listen Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className='p-4 md:p-8 bg-white mb-4'>

            <div className='flex'>
              <div className='w-1/5'>
                <img src='/media/friends.jpg' className='block w-full' alt="Friends Single Artwork"/>
              </div>
              <div className='w-4/5'>
                <div className='pl-8'>
                  <h2 className='text-xl md:text-4xl uppercase tracking-wider pb-4 font-bold'>"Friends" Single</h2>
                  <div className='block md:flex -mx-4'>
                    <div className='w-full'>
                      <a
                        href="https://orcd.co/niiva_friends"
                        className="flex items-center justify-center border border-niiva-purple border-opacity-50 rounded-full py-2 px-4 mb-2 transition hover:border-opacity-100" 
                        target="_BLANK" rel="noopener noreferrer">
                        <i className={`mr-2`}></i> Listen Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='p-4 md:p-8 bg-white'>
            <div className='flex'>
              <div className='w-1/5'>
                <img src='/media/albumart.png' className='block w-full' alt="NIIVA"/>
              </div>
              <div className='w-4/5'>
                <div className='pl-8'>
                  <h2 className='text-xl md:text-4xl uppercase tracking-wider pb-4 font-bold'>NIIVA Debut EP</h2>
                  <div className='block md:flex -mx-4'>
                    <div className='w-full md:w-1/2 px-4'>
                      {links.map(link =>
                        <a
                          key={link.id + '_link'}
                          href={link.link}
                          className="flex items-center justify-center border border-niiva-purple border-opacity-50 rounded-full py-2 px-4 mb-2 transition hover:border-opacity-100" 
                          target="_BLANK" rel="noopener noreferrer">
                          <i className={`${link.icon} mr-2`}></i> {link.label}
                        </a>
                      )}
                    </div>
                    <div className='w-full md:w-1/2 px-4'>
                      <ol className='list-decimal list-inside text-lg'>
                        <li className='mb-2'>All In</li>
                        <li className='mb-2'>Trigger</li>
                        <li className='mb-2'>Stupid Me</li>
                        <li className='mb-2'>Love Games</li>
                        <li className='mb-2'>Bad Decision</li>
                        <li className='mb-2'>Sweeter</li>
                        <li className='mb-2'>Welcome Back</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listen