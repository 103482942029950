import React from 'react'

const About = () => {
  return (
    <div className='z-40 absolute top-20 md:top-0 right-0 bottom-0 glass w-full md:w-3/5'>
      <div className='h-full w-full flex items-center justify-center'>
        <span className='text-white uppercase font-bold tracking-wider text-lg'>Coming soon</span>
      </div>
    </div>
  )
}

export default About