import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const CloseLink = () => {
  return (
    <div className='hidden lg:inline-block absolute top-5 left-5 text-white'>
      <NavLink to='/'>
        <FontAwesomeIcon icon={faTimes} />
      </NavLink>
    </div>
  )
}

export default CloseLink