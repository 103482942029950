import React from 'react'
import CloseLink from '../CloseLink'

const About = () => {
  return (
    <>
      <div className='z-40 absolute top-20 md:top-0 right-0 bottom-0 glass w-full md:w-3/5'>
        <CloseLink />
        <div className='h-full w-full overflow-y-auto hide-scrollbar'>
          <div className='p-4 md:p-16 text-white'>
            <h1 className='text-sm text-black hidden md:block uppercase font-bold text-white'>About</h1>
            <h2 className='hidden md:block text-6xl text-black font-bold mb-6 text-white'>NIIVA</h2>
            
            <p className='text-2xl tracking-wider leading-10 glass-above mb-6 text-justify text-white bg-black py-4 px-6'>
              NIIVA, the Bulgarian-born, England-raised, and now Canadian-based pop artist, has captivated audiences with her musical prowess. Recognized as iHeart Radio's Future Star, KiSS RADiO's One To Watch, and Amazon Music Canada's Breakthrough Artist, NIIVA soared to success with her Billboard charting hit single "Friends," reaching #13 on CHR. Now, dive into the feverish world of irresistible pop with NIIVA's new record, "Belladonna"! With lyrics that sizzle and a beat that heats up like a sauna, this track is a prescription for the ultimate summer anthem, guaranteed to leave you breathless, craving more, and singing along within seconds. Get ready to surrender to the poison you can't resist. "Belladonna" is in your blood now.
            </p>

            <p className='text-2xl tracking-wider leading-10 glass-above mb-6 text-justify text-white bg-black py-4 px-6'>
              The singer's independent debut EP has garnered over 3 million streams on Spotify alone, displaying her ability to weave relatable stories with ease. An established performer and songwriter, NIIVA’s collaboration roster includes esteemed songwriters like Joel Stouffer (Carly Rae Jepsen, Dragonette), Terence "TEE" Lam (Drake, Lu Kala), LØLØ, Sammy G & Bobby Brass from Kuya (Alessia Cara, Meghan Trainor), Matt Beckley (Camila Cabello, Avril Lavigne), Adam Josh (Loony, Shopé), Giordan Postorino (LØLØ).
            </p>

            <p className='text-2xl tracking-wider leading-10 glass-above mb-6 text-justify text-white bg-black py-4 px-6'>
              NIIVA has also made her mark in the K-Pop industry, notably co-writing the record-breaking debut single "Black Mamba" for the chart-topping girl group aespa. The track achieved phenomenal success, reaching No. 1 on the Billboard World Album chart, No. 1 on the Top Album Sales chart, No. 1 on the Top Current Album Sales and No. 3 on the Billboard 200 chart. While a guest on eTalk, NIIVA was presented with the SOCAN No. 1 Song Award for “Black Mamba”s success. 
            </p>

            <p className='text-2xl tracking-wider leading-10 glass-above mb-6 text-justify text-white bg-black py-4 px-6'>
              With signature catchy melodies NIIVA confidently takes the reins, delivering a sort of lyrical liquid courage in a defiant yet approachable voice.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default About