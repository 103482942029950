import React, { useState, useEffect} from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  position: relative;
  padding-top: 100%;
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

const Butterfly = () => {
  const [active, setActive] = useState(1)
  useEffect(() => {
    let timer = null
    timer = setTimeout(() => {
      setActive(active === 4 ? 1 : active + 1)
    }, 200)
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [active, setActive])
  return (
    <Container>
      <Inner>
        <img src='/media/butters/butt_1.png' className={`w-full ${active === 1 ? `opacity-100` : `opacity-0`}`} alt="Buterfly 1"/>
        <img src='/media/butters/butt_2.png' className={`w-full ${active === 2 ? `opacity-100` : `opacity-0`}`} alt="Buterfly 2"/>
        <img src='/media/butters/butt_3.png' className={`w-full ${active === 3 ? `opacity-100` : `opacity-0`}`} alt="Buterfly 3"/>
        <img src='/media/butters/butt_2.png' className={`w-full ${active === 4 ? `opacity-100` : `opacity-0`}`} alt="Buterfly 4"/>
      </Inner>
    </Container>
  )
}

export default Butterfly