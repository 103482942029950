import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import AnimationContainer from './Components/AnimationContainer'
import { Switch, Route } from "react-router-dom"
import SocialBar from './Components/SocialBar'
import NavLinks from './Components/NavLinks'
import styled from '@emotion/styled'
import Horizontal from './media/bella/bg.jpg'
import Vertical from './media/bella/bg_vert.jpg'
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import Thumbnail from './media/bella/artwork.png'

const Wrapper = styled.div`
  background-image: url(${Horizontal});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    background-image: url(${Vertical});
  }
`

const App = () => {
  const [open, setOpen] = useState(false)
  const location = useLocation()
  return (
    <div>
      <Wrapper className='fixed top-0 right-0 bottom-0 left-0'>
        <Switch>
          <Route path="*">
            <AnimationContainer />
          </Route>
        </Switch>
        <div className='z-30 absolute top-0 right-0 bottom-0 left-0'>
          {location.pathname !== '/' && (
            <NavLink to='/' className='absolute top-0 right-0 bottom-0 left-0 cursor-default' style={{ zIndex: 4 }}/>
          )}
          <div className='hidden md:block absolute top-1/2' style={{ width: 350, transform: 'translateY(-50%)', zIndex: 5 }}>
            <div className='pl-8'>
              <NavLink to='/'>
                <h2 className='inline-block text-6xl text-black font-bold mb-6 text-white'>NIIVA</h2>
              </NavLink>
              <NavLinks />
              <div className='pt-8'>
                <SocialBar />
              </div>
            </div>
          </div>
          <div className='block md:hidden absolute top-0 left-0 right-0 p-4 z-30'>
            <div className='flex items-center justify-between'>
              <h2 className='inline-block text-4xl text-black font-bold text-white'>NIIVA</h2>
              <NavLink to='/' className='text-black text-3xl p-1' onClick={() => setOpen(true)}>
                <FontAwesomeIcon icon={faBars} />
              </NavLink>
            </div>
          </div>
          {open && (
            <>
              <div className='absolute top-0 right-0 bottom-0 left-0 z-50 glass pt-16' style={{zIndex: '1000'}}>
                <div className='block absolute top-0 left-0 right-0 p-4 z-10'>
                  <div className='flex items-center justify-between'>
                    <h2 className='inline-block text-4xl text-black font-bold text-white'>NIIVA</h2>
                    <button className='text-white text-3xl p-1' onClick={() => setOpen(false)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </div>
                <div className='px-3'>
                  <NavLinks onClick={() => setOpen(false)}/>
                </div>
              </div>
            </>
          )}
          {/* <div className='absolute left-3 bottom-3 bg-black p-2'>
            <div className='flex items-center'>
              <img src='/media/factor/factor.svg' className='w-28 md:w-32 block mr-1' alt="Factor"/>
              <img src='/media/factor/canada.svg' className='w-48 md:w-64 block' alt="Canada"/>
            </div>
          </div> */}
        </div>
        {!open && location.pathname === '/' && (
          <div className='z-40 absolute left-0 md:left-auto top-24 md:top-0 right-0 pl-8 bottom-0 pr-8 md:pl-0'>
            <div className='h-full flex items-center'>
              <a href="https://orcd.co/niiva-belladonna" className='w-full md:w-96 block p-4 rounded-md bg-white shadow-lg'>
                <img src={Thumbnail} className='w-full md:w-auto mb-4' alt="Belladonna Artwork"/>
                <div>
                  <h3 className='text-2xl text-center uppercase font-bold mb-2'>"Belladonna"</h3>
                  <h4 className='text-sm opacity-75 text-center uppercase mb-4'>August 9</h4>
                  <p className='text-center font-bold text-2xl'>
                    Pre-save now
                  </p>
                </div>
              </a>
            </div>
          </div>
        )}
      </Wrapper>
    </div>
  )
}

export default App
