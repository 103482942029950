import React from 'react'
import { NavLink } from 'react-router-dom'

const NavLinks = ({
  onClick = () => {}
}) => {
  return (
    <>
      {['listen', 'watch', 'about'].map(link =>
        <div key={link}>
          <NavLink
            onClick={onClick}
            to={`/${link}`}
            className={`text-white relative text-black text-7xl uppercase tracking-wider font-bold transition focus:outline-none opacity-100 hover:translate-x-2 mb-2 inline-block`}
            activeClassName='opacity-100 underline'>
            {link}
            {/* <div className='active-butterfly'>
              <Butterfly />
            </div> */}
          </NavLink> 
        </div>
      )}
    </>
  )
}

export default NavLinks